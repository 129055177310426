<template>
    <b-row>
      <b-col>
        <b-card title="Notificacion de Tareas">
            <div v-if="true">
                <p class="text-white p-2 bg-danger d-flex rounded-sm justify-content-center" style="width: 10rem;" >En desarrollo</p>
            </div>
        </b-card>
      </b-col>
    </b-row>
  </template>
  <script>
  import axios from "axios";
//   import toastr from "toastr";
  import { mapGetters } from "vuex";
  export default {
    data() {
      return {
        id: this.$route.params.id,
        loading: false,
        oTask: {},
      };
    },
    mounted() {
      this.loadTurn(this.id);
    },
    computed: {
      ...mapGetters({
        user: "auth/user",
      }),
    },
    methods: {
      async onGetTask() {
        try {
            const { data , status } = await axios();
            if (status === 200) {
                console.log({ data });
            }
        } catch (error) {
            console.log(error);
        }
      },
    },
  };
  </script>
  